@mixin bottomShadow($color) {
    box-shadow: 0px 2px 0px 0px $color;
}

@mixin circle($size, $color: rgba(0,0,0,0)) {
    border-radius: 50%;
    width: $size;
    height: $size;
    content: ' ';
    vertical-align: middle;
    background-color: $color;
}

@mixin bootstrapButton($name, $backgroundColor, $color: null, $border: 0, $darkenPercentage: 5%) {
    .btn-#{$name} {
        &, &:focus {
            background-color: $backgroundColor !important;
            border: $border;
            color: $color !important;
            border-radius: 5px;
            @include bottomShadow(darken($backgroundColor, $darkenPercentage));
        }

        &.active, &.focus, &:active, &:focus, &:hover, .open > & {
            color: $color;
            background-color: darken($backgroundColor, $darkenPercentage) !important;
            border: $border;
            @include bottomShadow(darken($backgroundColor, $darkenPercentage * 4));
        }
    }
}

@mixin border-radius($radius) {
    border-radius: $radius;
}

@mixin border-left-radius($radius) {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
}

@mixin custom-scroll($width, $thumb-color, $track-color: transparent) {
    &::-webkit-scrollbar { width: $width; }
    &::-webkit-scrollbar-track {
        background-color: $track-color;
        border-radius: $width / 2;
    }
    &::-webkit-scrollbar-thumb  {
        background-color: $thumb-color;
        border-radius: $width / 2;
    }
}

@mixin limitWidthWithEllipsis($max-width) {
    max-width: $max-width;
    overflow: hidden;
    text-overflow: ellipsis;
}